@tailwind base;
@tailwind components;
@tailwind utilities;

.tooltip {
  @apply invisible absolute scale-75 opacity-0 transition-all;
}

.has-tooltip:hover .tooltip {
  @apply visible z-10 scale-100 opacity-100;
}

.has-tooltip-centered {
  @apply has-tooltip flex flex-col items-center justify-center;
}

.tooltip-white {
  @apply mt-14 rounded-lg border bg-white/80 p-1 px-2 text-sm shadow-md backdrop-blur-md;
}

.tooltip-black {
  @apply mt-14 rounded-lg border border-black bg-black/80 p-1 px-2 text-sm shadow-md backdrop-blur-md;
}

.marker-hidden::-webkit-details-marker {
  display: none;
}

.paper {
  @apply w-full rounded-lg bg-white shadow;
}

/* if backdrop support: very transparent and blurred */
@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
  .bg-white-blur {
    @apply bg-white/70 backdrop-blur-md;
  }
}

a.standalone {
  @apply text-indigo-600 hover:text-indigo-800;
}

@layer components {
  .button {
    @apply flex items-center justify-center
      rounded border border-gray-400/30 bg-white p-2 px-5 text-center text-black shadow-lg shadow-black/5
      ring-gray-500 hover:bg-gray-100 focus:outline-none focus-visible:ring-2 active:bg-gray-200;
  }

  .button-primary {
    @apply flex items-center justify-center
      rounded border border-indigo-400 bg-indigo-500 p-2 px-5 text-center text-white shadow-lg shadow-indigo-300/30
      ring-indigo-900 hover:bg-indigo-600 focus:outline-none focus-visible:ring-2 active:bg-indigo-400
      disabled:border-blue-100 disabled:bg-indigo-200 disabled:text-indigo-400 disabled:shadow-indigo-300/10;
  }

  .button-danger {
    @apply rounded text-red-500 hover:bg-red-200 active:bg-red-500 active:text-white disabled:pointer-events-none
      disabled:opacity-50;
  }
}

.segment + .segment {
  margin-top: 2rem;
}

.shine {
  position: absolute;
  top: -50%;
  left: -50%;
  width: 100%;
  height: 97%;
  background: radial-gradient(
    circle,
    rgba(183, 63, 251, 1) 0%,
    rgba(248, 0, 255, 0) 100%
  );
  transform-origin: 102% 100%;
}

@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes blur-in {
  from {
    opacity: 0.25;
    filter: blur(0.5rem);
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    filter: blur(0);
    transform: scale(1);
  }
}

@keyframes loading-bar {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(100%);
  }
}

@view-transition {
  navigation: auto;
  animation-duration: 2s;
}

@layer base {
  :root {
    --color-1: 0 100% 63%;
    --color-2: 270 100% 63%;
    --color-3: 210 100% 63%;
    --color-4: 195 100% 63%;
    --color-5: 90 100% 63%;
  }
  .dark {
    --color-1: 0 100% 63%;
    --color-2: 270 100% 63%;
    --color-3: 210 100% 63%;
    --color-4: 195 100% 63%;
    --color-5: 90 100% 63%;
  }
}

/* Hey! Only use this for really long text blobs like
legal text. DO NOT use this as "base style" for a whole page */
.text-content {
  @apply hyphens-auto text-sm leading-normal text-slate-700;
}

.text-content h2 {
  @apply mb-2 mt-6 text-lg font-semibold;
}

.text-content h3 {
  @apply mb-2 mt-4 text-base font-semibold;
}

.text-content a {
  @apply text-primary-900 underline;
}

.text-content a[target='_blank']::before {
  content: '↗ ';
  @apply mr-1 inline-block;
}

.text-content section {
  @apply mb-4;
}

.text-content ul {
  @apply list-inside list-disc py-2 pl-4;
}

.text-content ol {
  @apply list-inside list-decimal py-2 pl-4;
}
